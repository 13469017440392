import React from 'react';
import { graphql } from 'gatsby';

import { Link } from 'gatsby-plugin-react-i18next';

import { useLocale } from '@app/hooks';
import { Layout, Seo } from '@app/components';

const Thanks: React.FC = () => {
  const SCOPE_OPTIONS = {
    scope: 'pages.thanks',
  };
  const { t } = useLocale();

  return (
    <Layout>
      <Seo title="Thanks - Clinic SBB International" />
      <div className="pt-80 pb-48">
        <div className="container mx-auto px-1.5 text-center">
          <h2 className="text-6xl font-bold text-primary">{t('labels.title', SCOPE_OPTIONS)}</h2>
          <p className="text-xl my-10 text-gray-500">{t('labels.description', SCOPE_OPTIONS)}</p>
          <Link
            to="/"
            className="inline-block bg-primary py-4 px-10 text-lg rounded text-white font-bold hover:bg-red-800 cursor-pointer transition-all duration-300"
          >
            {t('actions.goHome', SCOPE_OPTIONS)}
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default Thanks;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
